<template>
  <section class="hero v2 flex flex-col lg:flex-row">
    <picture
      v-if="hero && hero.media.type === 'image'"
      :style="`background-color: ${hero.text.backgroundColor}`"
      class="w-full lg:w-[44%]"
      :class="[
        hero.firstItemMobile === 'media' ? 'order-1' : 'order-2',
        hero.firstItemDesktop === 'media' ? 'lg:order-1' : 'lg:order-2',
      ]"
    >
      <source media="(max-width: 768px)" :srcset="hero.media.path.mobile" type="image/jpeg" />
      <source media="(min-width: 769px)" :srcset="hero.media.path.desktop" type="image/jpeg" />
      <img
        :src="hero.media.path.desktop"
        :alt="hero.media.alt"
        class="block w-full"
        width="100%"
        height="100%"
        :class="hero.media.maxHeight ? `max-h-[${hero.media.maxHeight}]` : ''"
        loading="lazy"
      />
    </picture>
    <video
      v-if="hero && hero.media.type === 'video'"
      autoplay
      muted
      loop
      playsInline
      class="w-full lg:w-[44%]"
      width="100%"
      height="100%"
      :poster="hero.media.thumb"
      :class="[
        hero.firstItemMobile === 'media' ? 'order-1' : 'order-2',
        hero.firstItemDesktop === 'media' ? 'lg:order-1' : 'lg:order-2',
      ]"
      :src="isMobile ? hero.media.path.mobile : hero.media.path.desktop"
    ></video>

    <div
      v-if="hero && hero.media.type === 'slot'"
      class="block w-full"
      :class="[
        hero.firstItemMobile === 'media' ? 'order-1' : 'order-2',
        hero.firstItemDesktop === 'media' ? 'lg:order-1' : 'lg:order-2',
      ]"
      :style="`background-color: ${hero.text.backgroundColor}`"
    >
      <slot></slot>
    </div>
    <div
      v-if="hero && hero.text.title != 'Quer uma divulgação personalizada?'"
      :class="[
        hero.firstItemMobile === 'media' ? 'order-2' : 'order-1',
        hero.firstItemDesktop === 'media' ? 'lg:order-2' : 'lg:order-1',
      ]"
      class="flex w-full flex-col px-6 max-lg:order-1 lg:w-[56%] lg:px-16"
      :style="
        hero.firstItemDesktop === 'text'
          ? 'background: linear-gradient(275deg, #EFEFF1 23.08%, #FFF 77.42%);'
          : `background-color: ${hero.text.backgroundColor}`
      "
    >
      <div
        class="flex w-full flex-col gap-5 max-lg:py-14 lg:my-auto lg:pr-20"
        :class="hero.firstItem === 'media' ? '' : '1xl:ml-auto'"
        :style="`color: ${hero.text.color}`"
      >
        <hgroup>
          <h2
            v-if="hero.text.title"
            class="font-bold leading-tight"
            :class="[hero.text.subtitle != '' ? 'text-6xl lg:text-[2.92rem]' : 'text-5xl lg:text-[2.92rem]']"
            v-html="hero.text.title"
          />
          <h3
            v-if="hero.text.subtitle"
            class="mt-3 text-4xl leading-tight lg:text-[1.75rem]"
            v-html="hero.text.subtitle"
          />
          <h4
            v-if="hero.text.description"
            class="mt-8 text-2xl leading-tight lg:text-lg"
            v-html="hero.text.description"
          />
        </hgroup>
        <div class="mt-8">
          <template v-if="!buttonV2">
            <Button
              v-if="hero.cta?.show"
              class="flex items-center gap-[7px] rounded-[6px] border !border-[var(--button-borderColor,#FFF)] !bg-[var(--button-background,#FFF)] p-[10.5px_17.5px] !text-[var(--button-secondary-color,#000000)]"
              @click="redirect(hero.cta)"
            >
              {{ hero.cta.label }}
            </Button>

            <Button
              v-else-if="hero.cta"
              class="flex items-center gap-[7px] rounded-[6px] border !border-[var(--button-borderColor,#FF4F00)] !bg-[var(--button-background,#FF4F00)] p-[10.5px_17.5px]"
              @click="redirect(hero.cta)"
            >
              {{ hero.cta.label }}
            </Button>
          </template>
          <template v-if="buttonV2">
            <ButtonV2
              class="max-lg:mx-auto"
              :href="props.hero.cta.href || props.hero.cta.url"
              :target="props.hero.cta.target"
            >
              {{ props.hero.cta.label }}
            </ButtonV2>
          </template>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="[
        hero.firstItemMobile === 'media' ? 'order-2' : 'order-1',
        hero.firstItemDesktop === 'media' ? 'lg:order-2' : 'lg:order-1',
      ]"
      class="flex w-full items-center justify-end gap-[32px] self-stretch px-[32px] py-[24px] lg:py-[50px] lg:pl-[80px] lg:pr-[12px]"
      :style="
        hero.firstItemMobile === 'media'
          ? 'background: linear-gradient(275deg, #EFEFF1 23.08%, #FFF 77.42%);'
          : `background-color: ${hero.text.backgroundColor}`
      "
    >
      <div
        class="flex flex-col items-start lg:ml-[100px] lg:gap-[20px]"
        :class="hero.firstItem === 'media' ? '' : '1xl:ml-auto'"
        :style="`color: ${hero.text.color}`"
      >
        <hgroup>
          <h2
            v-if="hero.text.title"
            class="font-bold leading-tight"
            :class="[hero.text.subtitle != '' ? 'text-6xl lg:text-[2.92rem]' : 'text-5xl lg:text-[2.92rem]']"
            v-html="hero.text.title"
          />
          <h3 v-if="hero.text.subtitle" class="mt-3 text-4xl leading-tight lg:text-lg" v-html="hero.text.subtitle" />
          <h4
            v-if="hero.text.description"
            class="mt-8 text-2xl leading-tight lg:text-4xl"
            v-html="hero.text.description"
          />
        </hgroup>
        <div class="mt-8">
          <Button
            v-if="hero.cta?.show"
            class="flex items-center gap-[7px] rounded-[6px] border !border-[var(--button-borderColor,#FFF)] !bg-[var(--button-background,#FFF)] p-[10.5px_17.5px] !text-[var(--button-secondary-color,#000000)]"
            @click="redirect(hero.cta)"
          >
            {{ hero.cta.label }}
          </Button>

          <Button
            v-else-if="hero.cta"
            class="flex items-center gap-[7px] rounded-[6px] border !border-[var(--button-borderColor,#FF4F00)] !bg-[var(--button-background,#FF4F00)] p-[10.5px_17.5px]"
            @click="redirect(hero.cta)"
          >
            {{ hero.cta.label }}
          </Button>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";

import ButtonV2 from "@/components/Buttons/Button/v2/Button.vue";
import useIsMobile from "@/hooks/useIsMobile";
import { trackButton } from "@/utils/analitycsHelper/button";
import Tracker from "@/utils/Tracker/Tracker";

const { isMobile } = useIsMobile();

const props = defineProps({
  hero: {
    type: Object,
    default: () => ({}),
  },
  backgroundColor: {
    type: String,
    default: null,
  },
  content: {
    type: Object,
    default: () => ({}),
  },
  firstItem: {
    type: Boolean,
    default: false,
  },
  buttonV2: {
    type: Boolean,
    default: false,
  },
});

const redirect = async (cta) => {
  const nameEvent = "button_" + cta.label.replace(/ /g, "-").toLowerCase();
  Tracker.getInstance().track(nameEvent, trackButton(cta.label + " Home"));
  window.open(cta.url, cta.target);
};

const hero = computed(() => props.hero);
</script>

<style lang="scss" scoped>
.gradiente {
  background: linear-gradient(275deg, #efeff1 23.08%, #fff 77.42%);
}
</style>
